import React from 'react';
import './comps.css'
import Bar from "./bar";
import {useTranslation} from "react-i18next";
const Contacts = () => {
    const {t, i18n} = useTranslation()
    let currentLanguage = i18n.language

    return (
        <div className="foot_container">
        <div className="comps__body comps__contact">
            <Bar active={4}/>

            {currentLanguage === "ru" ? <div className="comps__content w-full">
                <p>
    <span style={{color: "#FFFFFF"}}>
      <span className="footer-title-comp" style={{color: "#FFFFFF"}}>Контакты</span>
    </span>
                </p>
                <p>
                    <br/>
                    <span style={{fontSize: 14}}>
      Если у Вас возникли какие-либо вопросы, наши эксперты всегда готовы
      помочь! <br/>Свяжитесь с нами по электронной почте службы поддержки:{" "}
                        <span style={{color: "#FFFFFF"}}><br/>support@dune-ex.com</span><br/><span
                        style={{color: "#FFFFFF"}}>supp.duneex@gmail.com</span>
    </span><br/>
                    <span style={{color: "rgba(170, 170, 170, 1)"}}>ИНН: 689872345</span>
                </p>
            </div> : ""}
            {currentLanguage === "en" ? <div className="comps__content w-full">
                    <p>
        <span style={{color: "#FFFFFF"}}>
            <span className="footer-title-comp" style={{color: "#FFFFFF"}}>Contacts</span>
        </span>
                    </p>
                    <p>
                        <br/>
                        <span style={{fontSize: 14}}>
            If you have any questions, our experts are always ready to help! <br/>
            Contact us via email support: <br/>
            <span style={{color: "#FFFFFF"}}>support@dune-ex.com</span><br/>
            <span style={{color: "#FFFFFF"}}>supp.duneex@gmail.com</span>
        </span><br/>
                        <span style={{color: "rgba(170, 170, 170, 1)"}}>Tax ID: 689872345</span>
                    </p>
                </div> : ""}
            {currentLanguage === "de" ? <div className="comps__content w-full">
                    <p>
        <span style={{color: "#FFFFFF"}}>
            <span className="footer-title-comp" style={{color: "#FFFFFF"}}>Kontakte</span>
        </span>
                    </p>
                    <p>
                        <br/>
                        <span style={{fontSize: 14}}>
            Wenn Sie Fragen haben, stehen unsere Experten Ihnen jederzeit zur Verfügung! Kontaktieren Sie uns per E-Mail-Support: <br/>
            <span style={{color: "#FFFFFF"}}>support@dune-ex.com</span><br/>
            <span style={{color: "#FFFFFF"}}>supp.duneex@gmail.com</span>
        </span><br/>
                        <span style={{color: "rgba(170, 170, 170, 1)"}}>Steuernummer: 689872345</span>
                    </p>
                </div> : ""}


        </div>
        </div>
    );
};

export default Contacts;